<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <div class="col-lg-6 col-md-12 auth-container-left">
          <img
            class="img-fluid"
            src="../../assets/images/logo/logo_register.svg"
          />
        </div>
        <div class="col-lg-6 col-md-12 auth-container-right">
          <div class="auth-card">
            <div class="card-body">
              <notification-container />
              <h1>{{ $t("auth.recover_account") }}</h1>
              <be-form-error v-if="error" :message="error" />
              <form
                v-if="!showSuccessElement"
                class="form"
                @submit.prevent="handlePasswordResetForm"
              >
                <BaseInput
                  v-model="email"
                  :disabled="success"
                  :errorMessage="emailErrorMessage"
                  :isInvalid="$v.email.$error || errors.email !== undefined"
                  :placeholder="$t('auth.fields.email.placeholder')"
                  leftIconClass="icon-user"
                  type="email"
                  @blur="$v.email.$touch()"
                >
                </BaseInput>

                <p class="reset_pass_text">
                  {{ $t("auth.recover_account_desc") }}
                </p>

                <BaseAlert v-if="success" :message="success" type="success" />

                <BaseButton
                  :disabled="disabledSubmitBtn"
                  :loading="loading"
                  :showLoading="true"
                  button-class="be-btn blue primary font-weight-bold w-100"
                  type="submit"
                >
                  {{ $t("auth.reinitialize") }}
                </BaseButton>
                <div class="text-center f-14 mt-2">
                  {{ $t("auth.back_to_before") }}
                  <router-link :to="{ name: 'Login' }">
                    {{ $t("auth.identify") }}
                  </router-link>
                </div>
              </form>
              <div v-else>
                <img
                  class="success-image"
                  src="../../assets/images/success.png"
                />
                <div>
                  <p class="successful-title">{{ $t("auth.email_sent") }}</p>
                  <p class="successful-desc">
                    {{ $t("auth.email_sent_desc") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { required, email } from "vuelidate/lib/validators";
import { recaptchaMixin } from "../../mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";
import BeFormError from "../../components/common/BeFormError";

export default {
  name: "PasswordReset",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer, BeFormError },

  data() {
    return {
      loading: false,
      email: null,
      success: null,
      error: null,
      errors: [],
      showSuccessElement: false,
    };
  },

  computed: {
    disabledSubmitBtn() {
      return (
        this.$v.$invalid ||
        this.loading ||
        this.success ||
        this.recaptchaToken === null
      );
    },
    emailErrorMessage() {
      if (this.$v.email.$error || this.errors.email) {
        if (!this.$v.email.required) {
          return this.$t("auth.errors.email.required");
        }
        if (!this.$v.email.email) {
          return this.$t("auth.errors.email.email");
        }
        if (this.errors.email) {
          return this.errors.email[0];
        }
      }
      return "";
    },
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },

  methods: {
    handlePasswordResetForm() {
      if (!this.$v.invalid) {
        this.error = null;
        this.loading = true;
        this.$store
          .dispatch("auth/sendResetPasswordLink", {
            email: this.email,
            recaptcha: this.recaptchaToken,
          })
          .then(message => {
            this.success = message;
            this.showSuccessElement = true;
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
            this.loadRecaptcha();
          });
      }
    },
  },

  validations: {
    email: { required, email },
  },
};
</script>

<style scoped></style>
